.vd-testimonial{
    text-align:center;
    > .vd-testimonial-image{
        > img{
            width:96px;
            height:96px;
        }
    }
    > .vd-testimonial-name{
        color: #000;
        font-weight: normal;
        font-size: 30px;
    }
    > .vd-testimonial-comment{
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
    }
}